import React, { useState, useEffect} from "react";
import { Layout, SEO } from "../layout";
import Header from "../layout/Header";
import MenuPage from "./../components/MenuPage/MenuPage";
import MenuPageEn from "./../components/MenuPage/MenuPageEn";

const Menu = () => {
   const [lang, setLang] = useState("si");

   useEffect(() => {
     const savedLang = localStorage.getItem("lang");
     if (savedLang) setLang(savedLang);
   }, [lang]);

  return lang === "si" ? (
    <Layout>
      <SEO title="Menu" />
      <Header setLang={setLang} />
      <MenuPage />
    </Layout>
  ) : (
    <Layout>
      <SEO title="Menu" />
      <Header setLang={setLang} />
      <MenuPageEn />
    </Layout>
  );
};

export default Menu;
