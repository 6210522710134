import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";

const MenuPageEn = () => (
  <Section>
    <MenuContainer>
      {/* PAGE ONE */}
      <SinglePage>
        <PageTitle>SUMMER 22 MENU</PageTitle>
        <PageTitleDescription>RESERVATION ONLY</PageTitleDescription>

        <Items>
          <ItemsTitle>Menu I</ItemsTitle>
          <SingleItem>
            <LeftColumn>STARTER</LeftColumn>
            <MainColumn>CHILLED VEGETABLE SOUP WITH MINT</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>MAIN</LeftColumn>
            <MainColumn>GRILLED TROUT / POLENTA / SALAD</MainColumn>
            <RightColumn>18,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>DESSERT</LeftColumn>
            <MainColumn>ICE CREAM WITH PUMPKIN OIL</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Meni II</ItemsTitle>
          <SingleItem>
            <LeftColumn>STARTER</LeftColumn>
            <MainColumn>CHILLED VEGETABLE SOUP WITH MINT</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>MAIN</LeftColumn>
            <MainColumn>
              GRILLED TROUT FILLET IN WINE LEAF / POLENTA / SALAD
            </MainColumn>
            <RightColumn>22,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>DESSERT</LeftColumn>
            <MainColumn>ICE CREAM WITH PUMPKIN OIL</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Meni III - Vegan</ItemsTitle>
          <SingleItem>
            <LeftColumn>STARTER</LeftColumn>
            <MainColumn>CHILLED VEGETABLE SOUP WITH MINT</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>MAIN</LeftColumn>
            <MainColumn>BLACK SPELT PASTA / VEGGIES / SALAD</MainColumn>
            <RightColumn>15,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>DESSERT</LeftColumn>
            <MainColumn>LAMUT APPLE SAUCE</MainColumn>
            <RightColumn>5,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>

      {/* PAGE TWO */}
      <SinglePage>
        <PageTitle>Drinks
        </PageTitle>

        <Items>
          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>LAMUT BIO APPLE JUICE</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>LAMUT BIO FILTERED APPLE JUICE</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>RADENSKA SPARKLING WATER</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>ELDERFLOWER SODA</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>ICED TEA</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>BELOKRANJEC - WHINE WINE</MainColumn>
            <RightColumn>10,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>WINE SPRITZER</MainColumn>
            <RightColumn>10,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>LAMUT BIO - RED WINE</MainColumn>
            <RightColumn>15,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>0.5l</LeftColumn>
            <MainColumn>VIZIR FUN TIME - CRAFT BEER</MainColumn>
            <RightColumn>3,50 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn>0.5l</LeftColumn>
            <MainColumn>MOCCHA ESPRESSO</MainColumn>
            <RightColumn>1,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>DOUBLE MOCCHA ESPRESSO</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>CAFE LATTE</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>TEA</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>

      {/* PAGE THREE */}
      <SinglePage>
        <PageTitle>A LA CARTE</PageTitle>
        <PageTitleDescription>
          GROUP OF 6 OR MORE PEOPLE / RESERVATIONS 24H IN ADVANCE
        </PageTitleDescription>

        <Items>
          <ItemsTitle>PREDJED</ItemsTitle>
          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>CHILLED VEGETABLE SOUP WITH MINT</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>BABA GANOUSH</MainColumn>
            <RightColumn>7,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>TROUT PATE</MainColumn>
            <RightColumn>9,00 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>MARINATED TROUT</MainColumn>
            <RightColumn>9,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Glavna jed</ItemsTitle>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>GRILLED TROUT</MainColumn>
            <RightColumn>16,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>GRILLED TROUT FILLET IN WINE LEAF</MainColumn>
            <RightColumn>20,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>GRILLED HUCHO</MainColumn>
            <RightColumn>30,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>LAMB UNDER THE BELL / POTATOES / VEGGIES</MainColumn>
            <RightColumn>26,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>BLACK SPELT PASTA WITH VEGGIES</MainColumn>
            <RightColumn>13,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>priloga</ItemsTitle>
          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>SEASONAL SALAD</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>BAKED POTATOES</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>GRILLED POLENTA</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>GRILLED VEGGIES</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>
      {/* PAGE FOUR */}

      <SinglePage>
        <PageTitle>DESERTS</PageTitle>

        <Items>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>ICE CREAM WITH PUMPKIN OIL</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>APPLE STRUDEL</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>TARRAGON ROLL</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>3 CREPES WITH JAM</MainColumn>
            <RightColumn>6,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>LAMUT APPLE SAUCE</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>
    </MenuContainer>
  </Section>
);

export default MenuPageEn;

const MenuContainer = styled.div`
  width: 100%;
`;

const SinglePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-bottom: 4rem;
  }
`;

const PageTitle = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
`;

const PageTitleDescription = styled.div`
  text-transform: uppercase;
  font-weight: 500;
`;

const Items = styled.div`
  margin: 2rem 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemsTitle = styled.div`
  align-self: center;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 2rem;
`;

const SingleItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;
`;
const LeftColumn = styled.div``;
const MainColumn = styled.div``;
const RightColumn = styled.div``;

const Section = styled(Wrapper)`
  margin-top: 70px;
  width: 95vw;
  font-size: 10px;
  /* == Mobile ===  */

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    font-size: 16px;
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-top: 200px;
    width: 50vw;
  }

  /*  Ultra Wide */
  @media (min-width: 2000px) {
    width: 40vw;
  }
`;
