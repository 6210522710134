import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";

const MenuPage = () => (
  <Section>
    <MenuContainer>
      {/* PAGE ONE */}
      <SinglePage>
        <PageTitle>Poletje 2022 Meni</PageTitle>
        <PageTitleDescription>Predhodna rezervacija</PageTitleDescription>

        <Items>
          <ItemsTitle>Meni I</ItemsTitle>
          <SingleItem>
            <LeftColumn>PREDJED</LeftColumn>
            <MainColumn>HLADNA ZELENJAVNA JUHA Z METO</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>GLAVNA JED</LeftColumn>
            <MainColumn>POSTRV NA ŽARU / POLENTA / SOLATA</MainColumn>
            <RightColumn>18,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>SLADICA</LeftColumn>
            <MainColumn>SLADOLED Z BUČNIM OLJEM</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Meni II</ItemsTitle>
          <SingleItem>
            <LeftColumn>PREDJED</LeftColumn>
            <MainColumn>HLADNA ZELENJAVNA JUHA Z METO</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>GLAVNA JED</LeftColumn>
            <MainColumn>
              FILE POSTRVI V VINSKEM LISTU NA ŽARU / POLENTA / SOLATA
            </MainColumn>
            <RightColumn>22,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>SLADICA</LeftColumn>
            <MainColumn>SLADOLED Z BUČNIM OLJEM</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Meni III - Vegan</ItemsTitle>
          <SingleItem>
            <LeftColumn>PREDJED</LeftColumn>
            <MainColumn>HLADNA ZELENJAVNA JUHA Z METO</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>GLAVNA JED</LeftColumn>
            <MainColumn>ČRNE PIRINE TESTENINE Z ZELENJAVO / SOLATA</MainColumn>
            <RightColumn>15,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>SLADICA</LeftColumn>
            <MainColumn>LAMUT JABOLČNA ČEZANA</MainColumn>
            <RightColumn>5,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>

      {/* PAGE TWO */}
      <SinglePage>
        <PageTitle>Pijača</PageTitle>

        <Items>
          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>LAMUT BIO JABOLČNI SOK</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>LAMUT BIO FILTRIRAN SOK</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>RADENSKA</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>ŠABESA</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>2dl</LeftColumn>
            <MainColumn>LEDENI ČAJ</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>BELOKRANJEC - BELO</MainColumn>
            <RightColumn>10,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>ŠPRICER</MainColumn>
            <RightColumn>10,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>1l</LeftColumn>
            <MainColumn>LAMUT BIO - RDEČE</MainColumn>
            <RightColumn>15,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>0.5l</LeftColumn>
            <MainColumn>VIZIR FUN TIME - CRAFT PIVO</MainColumn>
            <RightColumn>3,50 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn>0.5l</LeftColumn>
            <MainColumn>MOCCHA ESPRESSO</MainColumn>
            <RightColumn>1,50 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>DVOJNA MOCCHA ESPRESSO</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>KAVA Z MLEKOM</MainColumn>
            <RightColumn>2,50 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>ČAJ</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>

      {/* PAGE THREE */}
      <SinglePage>
        <PageTitle>A LA CARTE</PageTitle>
        <PageTitleDescription>
          SKUPINA 6 ALI VEČ LJUDI / PREDHODNA REZERVACIJ 24 UR V NAPREJ
        </PageTitleDescription>

        <Items>
          <ItemsTitle>PREDJED</ItemsTitle>
          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>HLADNA ZELENJAVNA JUHA Z METO</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>NAMAZ IZ MALANCAN BABAGANUŠ</MainColumn>
            <RightColumn>7,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>NAMAZ IZ POSTRV</MainColumn>
            <RightColumn>9,00 EUR</RightColumn>
          </SingleItem>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>MARINIRANA POSTRV</MainColumn>
            <RightColumn>9,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>Glavna jed</ItemsTitle>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>POSTRV NA ŽARU</MainColumn>
            <RightColumn>16,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>FILE POSTRVI V VINSKEM LISTU NA ŽARU</MainColumn>
            <RightColumn>20,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>SULEC NA ŽARU</MainColumn>
            <RightColumn>30,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>JAGENČEK V PEKI S KROMPIRJEM IN ZELENJAVO</MainColumn>
            <RightColumn>26,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>ČRNE PIRINE TESTENINE Z ZELENJAVO</MainColumn>
            <RightColumn>13,00 EUR</RightColumn>
          </SingleItem>
        </Items>

        <Items>
          <ItemsTitle>priloga</ItemsTitle>
          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>SEZONSKA SOLATA</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>PEČEN MLAD KROMPIR</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>POLENTA NA ŽARU</MainColumn>
            <RightColumn>2,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>ZELENJAVA NA ŽARU</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>
      {/* PAGE FOUR */}

      <SinglePage>
        <PageTitle>Sladice</PageTitle>

        <Items>
          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>SLADOLED Z BUČNIM OLJEM</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>JABOLČNI ŠTRUDELJ</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>PEHTRANOVA POTICA</MainColumn>
            <RightColumn>3,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn></LeftColumn>
            <MainColumn>3 PALAČINKE Z MARMELADO</MainColumn>
            <RightColumn>6,00 EUR</RightColumn>
          </SingleItem>

          <SingleItem>
            <LeftColumn>v</LeftColumn>
            <MainColumn>LAMUT JABOLČNA ČEZANA</MainColumn>
            <RightColumn>4,00 EUR</RightColumn>
          </SingleItem>
        </Items>
      </SinglePage>
    </MenuContainer>
  </Section>
);

export default MenuPage;

const MenuContainer = styled.div`
  width: 100%;
`;

const SinglePage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-bottom: 4rem;
  }
`;

const PageTitle = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
`;

const PageTitleDescription = styled.div`
  text-transform: uppercase;
  font-weight: 500;
`;

const Items = styled.div`
  margin: 2rem 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemsTitle = styled.div`
  align-self: center;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 2rem;
`;

const SingleItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;
`;
const LeftColumn = styled.div``;
const MainColumn = styled.div``;
const RightColumn = styled.div``;

const Section = styled(Wrapper)`
  margin-top: 70px;
  width: 95vw;
  font-size: 10px;
  /* == Mobile ===  */

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    font-size: 16px;
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-top: 200px;
    width: 50vw;
  }

  /*  Ultra Wide */
  @media (min-width: 2000px) {
    width: 40vw;
  }
`;
